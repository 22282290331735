import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Container as BootstrapContainer } from 'reactstrap';


const StyledContainer = styled(BootstrapContainer)`
  min-height: calc(100vh - 80px);
  display: flex;
  flex-flow: column;
  padding-bottom: 50px;

  ${breakpoint('sm')`
    min-height: calc(100vh - 95px);
    ${props => props.fullwidth === 'false' && css`
      width: 430px;
    `}
  `}

  ${breakpoint('lg')`
    min-height: calc(100vh - 120px);
    padding-top: 50px;
    ${props => props.fullwidth === 'false' && css`
      width: 460px;
    `}
  `}
`;


const PageContainer = ({children, fullwidth=false}) => (
  <StyledContainer fullwidth={fullwidth ? 'true' : 'false'}>{children}</StyledContainer>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  fullwidth: PropTypes.bool
}

export default PageContainer;