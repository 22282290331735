import React from "react"

import Header from 'components/Common/Layout/Header';
import PageContainer from 'components/Common/Layout/PageContainer';
import Layout from 'components/Layout/Layout';
import SEO from 'components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <PageContainer>
      <Header>Not Found</Header>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
