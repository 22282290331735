import React from 'react';
import PropTypes from 'prop-types';
import styled, { css }  from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledHeader = styled.h1`
  ${props => props.theme.main.fonts.application.h1};
  margin-bottom: 32px;
  text-align: ${props => props.align};
  
  ${props => !props.main && css`
    margin-top: 42px;
  `}

  ${breakpoint('sm')` 
    ${props => props.theme.main.fonts.application.h1};
    margin-top: 48px;
    margin-bottom: 32px;  
  `}
  sup {
    top: -0.3em;
    font-family: arial, sans-serif;
  }
`;


const Header = ({children, align = 'left', main = true, className = null}) => {
  return (<StyledHeader align={align} main={main} className={className}>{children}</StyledHeader>);
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string
}

export default Header;